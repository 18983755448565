(function(){

    var doc = document.documentElement;
    var w = window;

    var prevScroll = w.scrollY || doc.scrollTop;
    var curScroll;
    var direction = 0;
    var prevDirection = 0;

    var header = document.getElementById('site-header');

    if(!header){
        var header = document.getElementById('contactPageHeader');
    }

    var checkScroll = function() {

        /*
        ** Find the direction of scroll
        ** 0 - initial, 1 - up, 2 - down
        */

        curScroll = w.scrollY || doc.scrollTop;
        if (curScroll > prevScroll) {
            //scrolled up
            direction = 2;
        }
        else if (curScroll < prevScroll) {
            //scrolled down
            direction = 1;
        }

        if (direction !== prevDirection) {
            toggleHeader(direction, curScroll);
        }

        prevScroll = curScroll;

        if (window.scrollY == 0 ){
            header.classList.remove('fixed');
            header.classList.remove('shadow-lg');
        }
    };

    var toggleHeader = function(direction, curScroll) {
        if (direction === 2 && curScroll > 289) {

            //replace 52 with the height of your header in px

            header.classList.add('fixed');
            header.classList.add('shadow-lg');
            header.classList.add('-top-[186px]');
            prevDirection = direction;
        }
        else if (direction === 1) {
            header.classList.remove('-top-[186px]');
            prevDirection = direction;
        }
    };

    window.addEventListener('scroll', checkScroll);

})();
