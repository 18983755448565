document.addEventListener("DOMContentLoaded", function (event) {
    var cursor = document.querySelector(".custom-cursor");

    var initCursor = false;

    //A TAGS (LINKS)
    var links = document.querySelectorAll("a");
    for (var i = 0; i < links.length; i++) {
        var selfLink = links[i];

        selfLink.addEventListener("mouseover", function () {
            cursor.classList.add("custom-cursor--link");
        });
        selfLink.addEventListener("mouseout", function () {
            cursor.classList.remove("custom-cursor--link");
        });
    }

    //BUTTONS
    var buttons = document.querySelectorAll("button");
    for (var i = 0; i < buttons.length; i++) {
        var selfButton = buttons[i];

        selfButton.addEventListener("mouseover", function () {
            cursor.classList.add("custom-cursor--button");
        });
        selfButton.addEventListener("mouseout", function () {
            cursor.classList.remove("custom-cursor--button");
        });
    }

    //BUTTONS
    var buttonsMain = document.querySelectorAll(".btn-main");
    for (var i = 0; i < buttonsMain.length; i++) {
        var selfButtonMain = buttonsMain[i];

        selfButtonMain.addEventListener("mouseover", function () {
            cursor.classList.add("custom-cursor--button");
        });
        selfButtonMain.addEventListener("mouseout", function () {
            cursor.classList.remove("custom-cursor--button");
        });
    }

    //MENU TOP HEADER (NAVIGATIEBALK MET GELE ACHTERGROND)
    var menuTopHeader = document.getElementById('menuTopHeader');
    if(menuTopHeader){
        menuTopHeader.addEventListener("mouseover", function () {
            cursor.classList.add("custom-cursor--cursor_dark");
        });
        menuTopHeader.addEventListener("mouseout", function () {
            cursor.classList.remove("custom-cursor--cursor_dark");
        });
    }


    //MENU TOP HEADER (NAVIGATIEBALK MET GELE ACHTERGROND)
    var menuTopHeaderContact = document.getElementById('menuTopHeaderContact');
    if(menuTopHeaderContact){
        menuTopHeaderContact.addEventListener("mouseover", function () {
            cursor.classList.add("custom-cursor--cursor_main");
        });
        menuTopHeaderContact.addEventListener("mouseout", function () {
            cursor.classList.remove("custom-cursor--cursor_main");
        });
    }


    //SOCIAL ICOONTJES HEADER
    var socialIconsHeader = document.querySelectorAll("#socialIconsHeader a");
    for (var i = 0; i < socialIconsHeader.length; i++) {
        var selfSocialIcon = socialIconsHeader[i];

        selfSocialIcon.addEventListener("mouseover", function () {
            cursor.classList.add("custom-cursor--socialIconHeader");
        });
        selfSocialIcon.addEventListener("mouseout", function () {
            cursor.classList.remove("custom-cursor--socialIconHeader");
        });
    }

    //CONTACT PAGINA
    var contactPage = document.getElementById('contactPage');
    if(contactPage){
        contactPage.addEventListener("mouseover", function () {
            cursor.classList.add("custom-cursor--cursor_dark");
        });
        contactPage.addEventListener("mouseout", function () {
            cursor.classList.remove("custom-cursor--cursor_dark");
        });
    }

    //CONTACT PAGINA HEADER
    var contactPageHeader = document.getElementById('contactPageHeader');
    if(contactPageHeader){
        contactPageHeader.addEventListener("mouseover", function () {
            cursor.classList.add("custom-cursor--cursor_dark");
            cursor.classList.add("custom-cursor--header");
        });
        contactPageHeader.addEventListener("mouseout", function () {
            cursor.classList.remove("custom-cursor--cursor_dark");
            cursor.classList.remove("custom-cursor--header");
        });
    }

    var header = document.getElementById('site-header');
    if(header){
        header.addEventListener("mouseover", function () {
            cursor.classList.add("custom-cursor--header");
        });
        header.addEventListener("mouseout", function () {
            cursor.classList.remove("custom-cursor--header");
        });
    }

    //SOCIAL ICOONTJES CONTACT PAGINA HEADER
    var socialIconsHeaderContact = document.querySelectorAll("#socialIconsHeaderContact a");
    for (var i = 0; i < socialIconsHeaderContact.length; i++) {
        var selfSocialIconContact = socialIconsHeaderContact[i];

        selfSocialIconContact.addEventListener("mouseover", function () {
            cursor.classList.add("custom-cursor--socialIconHeader");
            cursor.classList.remove("custom-cursor--cursor_dark");
            cursor.classList.add("custom-cursor--cursor_main");
        });
        selfSocialIconContact.addEventListener("mouseout", function () {
            cursor.classList.remove("custom-cursor--socialIconHeader");
            cursor.classList.remove("custom-cursor--cursor_main");
        });
    }



    var subserviceItems = document.getElementsByClassName("subserviceItem");
    for (var i = 0; i < subserviceItems.length; i++) {
        var subserviceItem = subserviceItems[i];

        subserviceItem.addEventListener("mouseover", function () {
            // cursor.classList.add("custom-cursor--subserviceItem");
            // cursor.classList.remove("custom-cursor--link");
            cursor.classList.add("custom-cursor--cursor_dark");
        });
        subserviceItem.addEventListener("mouseout", function () {
            // cursor.classList.remove("custom-cursor--subserviceItem");
            cursor.classList.add("custom-cursor--cursor_dark");
        });
    }


    //SERVICE BLOK BOVENAAN
    var topServiceAlt = document.getElementById('topServiceAlt');
    if(topServiceAlt){
        topServiceAlt.addEventListener("mouseover", function () {
            cursor.classList.add("custom-cursor--cursor_dark");
        });
        topServiceAlt.addEventListener("mouseout", function () {
            cursor.classList.remove("custom-cursor--cursor_dark");
        });
    }

    //FOOTER CONTAINER TOP (GELE ACTERGROND)
    var footerContainerTop = document.getElementById('topContainerFooter');
    if(footerContainerTop){
        footerContainerTop.addEventListener("mouseover", function () {
            cursor.classList.add("custom-cursor--cursor_dark");
        });
        footerContainerTop.addEventListener("mouseout", function () {
            cursor.classList.remove("custom-cursor--cursor_dark");
        });
    }

    //COOKIEBANNER
    var cookiebanner = document.getElementById('cookiebanner');
    if(cookiebanner){
        cookiebanner.addEventListener("mouseover", function() {
            cursor.classList.add("custom-cursor--cookiebanner");
        });
        cookiebanner.addEventListener("mouseout", function() {
            cursor.classList.remove("custom-cursor--cookiebanner");
        });
    }

    var cookiebannerModals = document.getElementsByClassName('lcc-modal');
    for(var i = 0; i < cookiebannerModals.length ; i++){
        var cookiebannerModel = cookiebannerModals[i];

        cookiebannerModel.addEventListener("mouseover", function() {
            cursor.classList.add("custom-cursor--cookiebanner");
        });
        cookiebannerModel.addEventListener("mouseout", function() {
            cursor.classList.remove("custom-cursor--cookiebanner");
        });
    }


    window.onmousemove = function (e) {
        var mouseX = e.clientX;
        var mouseY = e.clientY;

        if (!initCursor) {
            // cursor.style.opacity = 1;
            TweenLite.to(cursor, 0.3, {
                opacity: 1
            });
            initCursor = true;
        }

        TweenLite.to(cursor, 0, {
            top: mouseY + "px",
            left: mouseX + "px"
        });
    };

    window.onmouseout = function (e) {
        TweenLite.to(cursor, 0.3, {
            opacity: 0
        });
        initCursor = false;
    };
});

